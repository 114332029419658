$theme-colors: (
  'primary': #556ee6,
  'secondary': #74788d,
  'success': #34c38f,
  'info': #50a5f1,
  'warning': #f1b44c,
  'danger': #f46a6a,
  'pink': #e83e8c,
  'light': #eff2f7,
  'dark': #343a40,
);

$display-font-sizes: (
  1: 24px,
  2: 20px,
  3: 18px,
  4: 16px,
  5: 14px,
  6: 12px,
);

$display-font-weight: 400;
$display-line-height: 20px;

$body-bg: #f8f8fb;

@each $color, $value in $theme-colors {
  .bg-soft-#{$color} {
    background-color: rgba(($value), 0.25) !important;
  }
}

@import '/node_modules/bootstrap';
